//File is used for API calls only
import { APIUrlList } from '../js/api_url';
import { _sendResponse } from '../js/common-function';
import { apiCall } from '../js/common-function';
import moment from 'moment-timezone';

export const getUserCalenderList = async (account_type) => {
    try {
        var startDate = moment().startOf('week');
        var endDate = moment().endOf('week');

        var start_date = startDate.format('MM/DD/YYYY');
        var end_date = endDate.format('MM/DD/YYYY');

        var data = {
            start_date: start_date,
            end_date: end_date,
        };

        var response;
        // var timezone = moment.tz.guess();
        if (account_type === '1') {
            response = await apiCall(APIUrlList.GETGABCALENARLIST, data, 'POST', true);
        } else {
            response = await apiCall(APIUrlList.GETFABCALENARLIST, data, 'POST', true);
        }

        var array = response.data.data;
        let newAds = [];
        if (array) {
            array.map(value => {
                value.slots.map(val => {
                    var obj = {};
                    obj["id"] = val.id;
                    var is_booked = 0;
                    if (account_type === '2') {
                        let booking = val.booking ? val.booking : null;
                        if (booking != null) {
                            if (val.my_slots === true) {
                                obj['slot_id'] = val.id;
                            } else {
                                obj['slot_id'] = val.slot_id;
                            }
                            obj['user_id'] = val.user_id;
                            let name = val.booking && val.booking.gab && val.booking.gab ? val.booking.gab["name"] : "";
                            let surname = val.booking && val.booking.gab && val.booking.gab ? val.booking.gab["surname"] : "";
                            if (val.call_type === 1) {
                                obj['username'] = val.booking && val.booking.gab && val.booking.gab.username ? val.booking.gab.username : "";
                                obj['call_type'] = val.call_type;
                                if (val.status === 3){
                                    obj["title"] = "You have cancelled this slot.";
                                } else {
                                    obj["title"] = "Video call with fans";
                                }
                                obj["gabName"] = name + ' ' + surname;
                                obj["gabPic"] = val.booking && val.booking.gab && val.booking.gab ? val.booking.gab["profile_pic_url"] : "";
                                obj["zoomID"] = val.booking.zoom_meeting_id;
                                obj['bookingId'] = val.booking.id;
                                obj['account_type'] = account_type;

                            } else {
                                obj['username'] = val.booking && val.booking.gab && val.booking.gab ? val.booking.gab.username : "";
                                if (val.status === 3){
                                    obj["title"] = "You have cancelled this slot.";
                                } else {
                                    obj["title"] = val.stream_type === 1 ? "Private stream with fans" : 'Public stream with fans';
                                }
                            }
                            obj['account_type'] = account_type;
                            obj["gabName"] = name + ' ' + surname;
                            obj["gabPic"] = val.booking && val.booking.gab && val.booking.gab ? val.booking.gab["profile_pic_url"] : "";
                            obj["zoomID"] = val.booking.zoom_meeting_id;
                            obj['bookingId'] = val.booking.id;
                            obj['call_type'] = val.call_type;
                            is_booked =1;
                        } else {
                            obj['user_id'] = val.user_id;
                            if (val.my_slots === true) {
                                if (val.call_type === 1) {
                                    if (val.status === 3){
                                        obj["title"] = "You have cancelled this slot.";
                                    } else {
                                        obj["title"] = "Video call with fans";
                                    }
                                    obj['slot_id'] = val.id;
                                } else {
                                    if (val.status === 3){
                                        obj["title"] = "You have cancelled this slot.";
                                    } else {
                                        obj["title"] = val.stream_type === 1 ? "Private stream with fans" : 'Public stream with fans';
                                    }
                                    obj['slot_id'] = val.id;
                                }
                            } else {
                                let meet_with_name = val.meet_with ? val.meet_with['name'] : "";
                                let meet_with_surname = val.meet_with ? val.meet_with['surname'] : "";
                                if (val.call_type === 1) {
                                    if (val.status === 3){
                                        obj["title"] = "Your Video call has been cancelled by "+ meet_with_name + ' ' + meet_with_surname;
                                    } else {
                                        obj["title"] = "Video call with "+ meet_with_name + ' ' + meet_with_surname;
                                    }
                                    obj['slot_id'] = val.slot_id;
                                    obj["gabName"] = meet_with_name + ' ' + meet_with_surname;
                                    obj["gabPic"] = val.meet_with ? val.meet_with["profile_pic_url"] : "";
                                    obj["zoomID"] = val.zoom_meeting_id;
                                    obj["bookingId"] = val.id;
                                    obj['account_type'] = account_type;
                                } else {
                                    if (val.status === 3){
                                        obj["title"] = "Your Live streaming has been cancelled by "+ meet_with_name + ' ' + meet_with_surname;
                                    } else {
                                        obj["title"] = "Live streaming with "+ meet_with_name + ' ' + meet_with_surname;
                                    }
                                    obj['slot_id'] = val.slot_id;
                                    obj["gabName"] = meet_with_name + ' ' + meet_with_surname;
                                    obj["gabPic"] = val.meet_with ? val.meet_with["profile_pic_url"] : "";
                                    obj["zoomID"] = val.zoom_meeting_id;
                                    obj["bookingId"] = val.id;
                                    obj['account_type'] = account_type;
                                }
                                obj['username'] = val.meet_with ? val.meet_with.username : "";
                                obj['call_type'] = val.call_type;
                            }
                        }
                        obj["my_slots"] = val.my_slots;
                    } else {
                        let meet_with_name = val.meet_with ? val.meet_with['name'] : "";
                        let meet_with_surname = val.meet_with ? val.meet_with['surname'] : "";
                        obj['user_id'] = val.user_id;
                        if (val.call_type === 1) {
                            obj['username'] = val.meet_with ? val.meet_with.username : "";
                            obj['call_type'] = val.call_type;
                            if (val.status === 3){
                                obj["title"] = "Your Video call has been cancelled by "+ meet_with_name + ' ' + meet_with_surname;
                            } else {
                                obj["title"] = "Video call with "+ meet_with_name + ' ' + meet_with_surname;
                            }
                            obj["gabName"] = meet_with_name + ' ' + meet_with_surname;
                            obj["gabPic"] = val.meet_with["profile_pic_url"];
                            obj["zoomID"] = val.zoom_meeting_id;
                            obj["bookingId"] = val.id;
                            obj['account_type'] = account_type;
                        } else {
                            obj['username'] = val.meet_with ? val.meet_with.username : "";
                            obj['call_type'] = val.call_type;
                            if (val.status === 3){
                                obj["title"] = "Your Live streaming has been cancelled by "+ meet_with_name + ' ' + meet_with_surname;
                            } else {
                                obj["title"] = "Live streaming with "+ meet_with_name + ' ' + meet_with_surname;
                            }
                            obj["gabName"] = meet_with_name + ' ' + meet_with_surname;
                            obj["gabPic"] = val.meet_with ? val.meet_with["profile_pic_url"] : "";
                            obj["zoomID"] = val.zoom_meeting_id;
                            obj["bookingId"] = val.id;
                            obj['account_type'] = account_type;
                        }
                    }
                    if (account_type === '2') {
                        obj['gabs_allowed'] = val.no_of_gab_allow;
                        let availableSlots = val.no_of_gab_allow - val.booking_count;
                        obj['gabs_remaining'] = availableSlots === 0 ? 'All slots booked' : `${availableSlots} slots left` ;
                        obj['stream_type'] = val.stream_type === 1 ? 'Private' : val.stream_type === 2 ? 'Public' : '';
                        //var start_date_time = moment(val.date).format('YYYY-MM-DD')+' '+val.start_time;
                        var fab_start_date_time = moment(val.start_time).format('YYYY-MM-DD HH:mm:ss');
                        var fab_start_date = new Date(fab_start_date_time.replace(/-/g, '/'));
                        fab_start_date = fab_start_date.toString();
                        //var end_date_time = moment(val.date).format('YYYY-MM-DD')+' '+val.end_time;
                        var fab_end_date_time = moment(val.end_time).format('YYYY-MM-DD HH:mm:ss');
                        var fab_end_date = new Date(fab_end_date_time.replace(/-/g, '/'));
                        fab_end_date = fab_end_date.toString();
                        obj["start"] = moment(fab_start_date).format('YYYY-MM-DD HH:mm:ss');
                        obj["end"] = moment(fab_end_date).format('YYYY-MM-DD HH:mm:ss');
                        obj["timezone"] = val.time_zone;
                        newAds.push(obj);

                        var Sdate = new Date(val.start_time).toString();
                        //let original_start_date = changeTimezone(Sdate, val.time_zone);
                        obj["start_original_time"] = moment(Sdate).format('hh:mm a');
                        obj["start_original_date"] = moment(Sdate).format('DD-MMM-yyyy');
                        obj["day"] = moment(Sdate).format('dddd');
                        var Edate = new Date(val.end_time).toString();
                        //let original_end_date = changeTimezone(Edate, val.time_zone);
                        obj["end_original_time"] = moment(Edate).format('hh:mm a');

                        let date1 = new Date();
                        let date2 = new Date(fab_start_date);
                        let date3 = new Date(fab_end_date);
                        let remainig = showDays(date1, date2,date3);
                        obj["remaining_time"] = remainig !== null ? remainig : [];
                        if (account_type === '2' && val.my_slots) {
                            if (is_booked === 1){
                                obj["color"] = "red";
                            } else {
                                obj["color"] = "green";
                            }
                        } else if (account_type === '2' && val.my_slots === false) {
                            obj["color"] = "#3788d8";
                        }
                        obj['call_status'] = val.status;
                    }
                    else {
                        obj['gabs_allowed'] = val.no_of_gab_allow;
                        let availableSlots = val.no_of_gab_allow - val.booking_count;
                        obj['gabs_remaining'] = availableSlots === 0 ? 'All slots booked' : `${availableSlots} slots left` ;
                        obj['stream_type'] = val.stream_type === 1 ? 'Private' : val.stream_type === 2 ? 'Public' : '';
                        //var start_date_time = moment(val.date).format('YYYY-MM-DD')+' '+val.start_time;
                        var start_date_time = moment(val.start_time).format('YYYY-MM-DD HH:mm:ss');
                        var start_date = new Date(start_date_time.replace(/-/g, '/')); //+' UTC'
                        start_date = start_date.toString();
                        //var end_date_time = moment(val.date).format('YYYY-MM-DD')+' '+val.end_time;
                        var end_date_time = moment(val.end_time).format('YYYY-MM-DD HH:mm:ss');
                        var end_date = new Date(end_date_time.replace(/-/g, '/')); //+' UTC'
                        end_date = end_date.toString();
                        obj["start"] = moment(start_date).format('YYYY-MM-DD HH:mm:ss');
                        obj["end"] = moment(end_date).format('YYYY-MM-DD HH:mm:ss');
                        obj["timezone"] = val.time_zone;
                        newAds.push(obj);

                        var SdateGab = new Date(val.start_time).toString(); //toUTCString();
                        let original_start_date = changeTimezone(SdateGab, val.time_zone);
                        obj["start_original_time"] = original_start_date.format('hh:mm a');
                        obj["start_original_date"] = original_start_date.format('DD-MMM-yyyy');
                        obj["day"] = original_start_date.format('dddd');
                        var EdateGab = new Date(val.end_time).toString(); //toUTCString();
                        let original_end_date = changeTimezone(EdateGab, val.time_zone);
                        obj["end_original_time"] = original_end_date.format('hh:mm a');

                        let date1 = new Date();
                        let date2 = new Date(start_date);
                        let date3 = new Date(end_date);
                        let remainig = showDays(date1, date2,date3);
                        obj["remaining_time"] = remainig !== null ? remainig : [];
                        if (account_type === '2' && val.my_slots) {
                            if (is_booked === 1){
                                obj["color"] = "red";
                            } else {
                                obj["color"] = "green";
                            }
                        } else if (account_type === '2' && val.my_slots === false) {
                            obj["color"] = "#3788d8";
                        }
                        obj['call_status'] = val.status;
                    }

                });
            });
        }
        return newAds;
    } catch (error) {
        // handle authentication and validation errors here
        _sendResponse("error", error);
    }
};

export const setTimezoneOptions = async () => {
    let timeZoneOptions = {timezones:[{value:"", text:'Select Timezone'}], offset:[], timezones2:[]};
    try {
        const response = await apiCall(APIUrlList.GETTIMEZONELIST, null, 'GET', false);
        if (response.data.status === true){
            response.data.data.map(value => {
                let objToPush = {
                    value: value.id,
                    text: value.timezone,
                };
                let objToPush2 = {
                    id: value.id,
                    text: value.timezone
                };
                timeZoneOptions.timezones.push(objToPush);
                timeZoneOptions.offset[value.id] = value.timezone_offset;
                timeZoneOptions.timezones2.push(objToPush2);
            });
            return timeZoneOptions;
        }
    } catch (error){
        _sendResponse('error', error);
    }
};

export const showMoreTimeSlotList = async (account_type,fab_id) => {

    var curr = new Date; // get current date
    var start_date1 = moment(curr).format('MM/DD/YYYY');

    var data = {
        fab_id: fab_id,
        start_date:start_date1
    };

    const response = await apiCall(APIUrlList.SHOWMORETIMESLOT, data, 'POST', true);

    var array = response.data.data;
    const showMoreSlot = [];

    if (array) {
        array.map(value => {
            var obj = {};


            var start_date = new Date(value.start_time.replace(/-/g, '/')); //+' UTC'
            start_date = start_date.toString();


            var today = moment().format('DD/MM/YY');
            var video_call_date = moment(start_date).format('DD/MM/YY');
            var weekDayName =  moment(start_date).format('dddd');
            if (today === video_call_date){
                weekDayName = "Today";
            }
            obj['gab_allowed'] = value.no_of_gab_allow;
            let availableSlots = value.no_of_gab_allow - value.total_booking_count;
            obj['gab_remaining'] = value.call_type === 1 ? `${availableSlots} slots left` : '-';
            var end_date = new Date(value.end_time.replace(/-/g, '/')); //+' UTC'
            end_date = end_date.toString();
            obj['id'] = value.id;
            obj['day'] = weekDayName;
            obj['date'] = moment(start_date).format('MMM DD');
            obj['year'] = moment(start_date).format('YYYY');
            obj['full_date'] = moment(start_date).format('YYYY-MM-DD');
            obj['start_time'] = moment(start_date).format('h:mm a');
            obj['end_time'] = moment(end_date).format('h:mm a');
            obj['time_zone'] = value.time_zone;
            var call_type = "video call";
            if (value.call_type == 2){
                call_type = "live streaming";
            }

            var minutes = Math.floor(value.time_interval / 60);
            var call_duration = minutes+"-minutes "+call_type;

            if (minutes >= 60){
                var hours = Math.floor(minutes / 60);
                var mins = minutes % 60;
                call_duration = hours+"-hour "+call_type;
                if (mins > 0){
                    call_duration = hours + "hours " + mins+"minutes "+call_type;
                }
            }
            obj['call_duration'] = call_duration;
            obj['price'] = parseFloat(value.price).toFixed(2);

            showMoreSlot.push(obj);
        });
    }

    var mainArr = groupBy(showMoreSlot,'full_date');
    return mainArr;
};

export const getSignature = async (meetingNumber) => {
    try {
        let response = await apiCall(APIUrlList.ZOOMSIGNATURE+`?meeting_id=${meetingNumber}&role=${0}`, {}, 'GET', true);
        return response.data.data;
    } catch (error){
        _sendResponse('error', error);
    }
};

function groupBy(xs, key) {
    return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

function changeTimezone(date, timeZone) {
    var momentDate = moment(date).format('YYYYMMDD HH:mm:ss');
    var cutoffString = momentDate; // in utc
    var utcCutoff = moment.utc(cutoffString, 'YYYYMMDD HH:mm:ss');
    return utcCutoff.clone().tz(timeZone);
}

export function showDays(date1, date2,endDate){
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
    let date3 = moment(date1).format("DD-MM-YYYY HH:mm:ss");
    let date4 = moment(date2).format("DD-MM-YYYY HH:mm:ss");

    var startTime = moment(date3, 'DD-MM-YYYY hh:mm:ss');
    var endTime = moment(date4, 'DD-MM-YYYY hh:mm:ss');
    var hoursDiff = endTime.diff(startTime, 'hours');
    var minsDiff = endTime.diff(startTime, 'minutes');
    var sec = moment.utc(endTime.diff(startTime)).format("HH:mm:ss");

    //time left for call to end
    var date5 = moment(endDate).format("DD-MM-YYYY HH:mm:ss");
    var callEndTime = moment(date5, 'DD-MM-YYYY hh:mm:ss');
    var call_secs_left = callEndTime.diff(startTime, 'seconds');
    var call_time_left = moment.utc(callEndTime.diff(startTime)).format("HH:mm:ss");
    return {leftDays:`${days} ${days === 1 ? 'day' : 'days'}`,days:days, hours:hoursDiff, minutes: minsDiff, time_left:sec, call_duration_left: call_secs_left, call_time_left: call_time_left};
}


export const getMeetingDetails = async (id) => {
    try {
        let response = await apiCall(APIUrlList.MEETINGDETAILS+`/${id}`, {}, 'GET', true);

        return response.data.data;
    } catch (error){
        _sendResponse('error', error);
    }
};


export const getSlotDetails = async (id) => {
    try {
        let ObjToSend = {
            slot_id: id,
        };
        let response = await apiCall(APIUrlList.SLOTDETAILS, ObjToSend, 'POST', true);

        return response.data.data;
    } catch (error){
        _sendResponse('error', error);
    }
};

export const getGabSlotDetails = async (id, gabId) => {
    try {
        let ObjToSend = {
            gab_id: gabId,
            slot_id: id,
        };
        let response = await apiCall(APIUrlList.GETGABSLOTDETAILS, ObjToSend, 'POST', true);

        return response.data.data;
    } catch (error){
        _sendResponse('error', error);
    }
};

export const cancelSlots = async (id) => {
    try {
        let ObjToSend = {
            slot_id: id
        };
        let response = await apiCall(APIUrlList.CANCELSLOT, ObjToSend, 'POST', true);
        if (response.data.status === true){
            _sendResponse('success', response.data.message);
        } else {
            _sendResponse('error', response.data.message);
        }
        return response.data.data;
    } catch (error){
        _sendResponse('error', error);
    }
};


export const getGabList = async (id) => {
    try {
        let response = await apiCall(APIUrlList.SLOTGABLIST + `/${id}`, {}, 'GET', true);
        return response.data.data;
    } catch (error) {
        _sendResponse('error', error);
    }
};

export const getFollowerList = async (slotId) => {
    try {
        let ObjToSend = {
            slot_id: slotId,
        };

        let fans = [];
        let response = await apiCall(APIUrlList.FOLLOWERLIST, ObjToSend, 'POST', true);
        if (response.data.data && response.data.data.length>0){
            fans.push({id:'all', text:'All'});
            response.data.data.map( value => {
                let objToPush = {
                    id: value.user_id,
                    text: value.name + " " + value.surname,
                };
                fans.push(objToPush);
            });
        } else {
            fans.push({id:'0', text:'No followers found', disabled: true});
        }
        return fans;
    } catch (error) {
        _sendResponse('error', error);
    }
};

export const getVideoCallList = async (type) => {
    try {
        let slots = [];
        let objToSend = {
            call_type: type
        };
        let response = await apiCall(APIUrlList.VIDEOSLOTLIST, objToSend, 'POST', true);
        if (response.data.data){
            response.data.data.map(value => {
                if (value.current_user_account_type == 2){
                    let sDateFab = new Date((value.start_time).replace(/-/g, '/'));
                    let objToPushFab = {
                        id: value.id,
                        text: moment(sDateFab).format('dddd')+': '+moment(sDateFab).format('YYYY-MM-DD')+ ' - '+ moment(sDateFab).format('hh:mm a'),
                    };
                    slots.push(objToPushFab);
                } else {
                    let sDate = new Date((value.start_time).replace(/-/g, '/')+' UTC');
                    let objToPush = {
                        id: value.id,
                        text: moment(sDate).format('dddd')+': '+moment(sDate).format('YYYY-MM-DD')+ ' - '+ moment(sDate).format('hh:mm a'),
                    };
                    slots.push(objToPush);
                }
                // let eDate = new Date((value.end_time).replace(/-/g, '/')+' UTC');


            });
        }

        return slots;
    } catch (error) {
        _sendResponse('error', error);
    }
};

export const sendInvitation = async (slotId, gabIds) => {
    try {
        let objToSend = {
            slot_id: slotId,
            gab_id: gabIds
        };

        let response = await apiCall(APIUrlList.SENDINVITATION, objToSend, 'POST', true);
        if (response.data.status === true){
            _sendResponse('success', response.data.message);
        } else {
            _sendResponse('error', response.data.message);
        }

        return response.data;
    } catch (error){
        _sendResponse('error', error);
    }
};

export const getInvitationList = async () => {
    try {
        let response = await apiCall(APIUrlList.INVITATIONLIST, {}, 'GET', true);
        return response.data.data;
    } catch (error){
        _sendResponse('error', error);
    }
};

export const rejectInvitation = async (id) => {
    try {
        let objToSend = {
            invitation_id: id,
            status:2
        };

        let response = await apiCall(APIUrlList.REJECTINVITATION, objToSend, 'POST', true);
        if (response.data.status === true){
            _sendResponse('success', response.data.message);
        } else {
            _sendResponse('error', response.data.message);
        }
        return response.data;
    } catch (error){
        _sendResponse('error', error);
    }
};
